@use '@angular/material' as mat;
@import "theme";
@import "material-symbols/outlined";

// Makes sizing simpler
*, *::before, *::after {
  box-sizing: border-box;
}

// remove default spacing
* {
  margin: 0;
  padding: 0;
}

// dark mode user-agent preference
html {
  color-scheme: dark light;
  hanging-punctuation: first last;
}

// responsive images/videos
img,
video {
  display: block;
  max-width: 100%;
}

h1.page-header {
  text-align: center;
}

// typography setup
h1, h2, h3, h4,h5, h6 {
  text-wrap: balance;
}

// dialog stuff
body.inheritCursors * {
  cursor: inherit !important;
}

html.cdk-global-scrollblock {
  overflow: hidden;
}


router-outlet + *:not(app-home) {
  padding-block: 24px;
  padding-inline: 16px;

  h1 {
    text-align: center;
  }

  & > :not(:first-child) {
    margin-top: 1rem;
  }

  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
}

.cdk-overlay-pane.video-dialog {
  justify-content: center;
  align-items: center;

  mat-dialog-container.mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cdk-overlay-pane.rounded mat-dialog-container {
  --mdc-dialog-container-shape: 1rem;
}



$scrollbar-size: 0.5em;
$scrollbar-thumb-border-width: 2px;
$scrollbar-track-color: mat.m2-get-color-from-palette($primary-palette, 50);
$scrollbar-thumb-color: mat.m2-get-color-from-palette($primary-palette, 500);

//@supports (scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color) {
//  * {
//    scrollbar-color: $scrollbar-thumb-color $scrollbar-track-color;
//    scrollbar-width: auto;
//  }
//}

@supports selector(::-webkit-scrollbar) {
  ::-webkit-scrollbar {
    width: $scrollbar-size;
    height: $scrollbar-size;
  }

  ::-webkit-scrollbar-track {
    //background-color: transparent;
    background-color: $scrollbar-track-color;
    border-radius: 100vw;
    margin-block: 0.5em;
  }

  ::-webkit-scrollbar-thumb {
    background: mat.m2-get-color-from-palette($primary-palette, 400);
    border: $scrollbar-thumb-border-width solid $scrollbar-track-color;
    border-radius: 100vw;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: mat.m2-get-color-from-palette($primary-palette, 800);
  }
}


mat-card {

  // Make the header text fill the available space
  .mat-mdc-card-header-text {
    flex-grow: 1;
  }
}


// Remove spinner from all input number elements
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
