@use '@angular/material' as mat;
@use 'sass:map';

@include mat.elevation-classes();
@include mat.app-background();

$primary-palette: mat.m2-define-palette(mat.$m2-teal-palette);
$accent-palette: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

$typography: mat.m2-define-typography-config();
$density: 0;

//$light-theme: mat.define-light-theme((
//  color: (
//    primary: $primary-palette,
//    accent: $accent-palette,
//    warn: $warn-palette,
//  ),
//  typography: $typography,
//  density: $density,
//));

$dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $primary-palette,
    accent: $accent-palette,
    warn: $warn-palette,
  ),
  typography: $typography,
  density: $density,
));

@include mat.all-component-themes($dark-theme);
//@include mat.all-component-themes($light-theme);
//
//.dark-theme {
//  @include mat.all-component-colors($dark-theme);
//}

.mat-headline-1 {
  $class: 'headline-1';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

.mat-headline-2 {
  $class: 'headline-2';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

.mat-headline-3 {
  $class: 'headline-3';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

.mat-headline-4 {
  $class: 'headline-4';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

h1,
.mat-headline-5 {
  $class: 'headline-5';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

h2,
.mat-headline-6 {
  $class: 'headline-6';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

h3,
.mat-subtitle-1 {
  $class: 'subtitle-1';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

label,
h4,
.mat-body-1 {
  $class: 'body-1';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

*,
.mat-body-2 {
  $class: 'body-2';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

.mat-body-strong,
.mat-subtitle-2 {
  $class: 'subtitle-2';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}

.mat-small,
.mat-caption {
  $class: 'caption';

  font-size:	mat.m2-font-size($typography, $class);
  font-family:	mat.m2-font-family($typography, $class);
  font-weight:	mat.m2-font-weight($typography, $class);
  line-height:	mat.m2-line-height($typography, $class);
  letter-spacing:	mat.m2-letter-spacing($typography, $class);
}
